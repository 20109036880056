<template>
  <div class="index">
    <div style="position: fixed;background:rgba(249, 249, 249, 1);width:100%;left:0;top:0;padding: 27px 16px 12px;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">分享好友</span>
    </div>
    <div style="padding:12px 0px;margin:15px 16px 0">
      <a-row>
        <a-col :span="8">
          <div style="color:rgba(44, 54, 74, 1);font-size:20px;font-weight: bold;">{{ commission.count }}</div>
          <div style="color:rgba(103, 110, 125, 1);font-size:12px;">我的分享</div>
        </a-col>
        <a-col :span="8">
          <div style="color:rgba(44, 54, 74, 1);font-size:20px;font-weight: bold;text-align: center">{{commission.total}}</div>
          <div style="color:rgba(103, 110, 125, 1);font-size:12px;text-align: center">累计消耗(￥)</div>
        </a-col>
        <a-col :span="8">
          <span @click="withdrawal" style="display: inline-block;width: 64px;height: 32px;line-height: 32px;text-align: center;background:rgba(120, 86, 255, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);color:#fff;font-size:13px;border-radius: 6px;float: right;margin-top:8px;">提现</span>
        </a-col>
      </a-row>
    </div>
    <div style="padding:12px 16px 24px;background:rgba(249, 249, 249, 1);overflow-y: auto;flex: 1" @scroll="handleScroll">
      <div style="">
        <div v-if="smsList.length!=0">
          <div style="height: 90px;display: flex;margin-top:16px;background:#fff;border-radius: 12px;padding: 15px 16px;" v-for="(item,index) in smsList" :key="index">
            <div style="display: inline-block;position: relative">
              <img :src="item.avatar" style="width: 36px;height: 36px;border-radius: 18px;" alt="">
            </div>
            <div style="flex: 1;margin:0 8px;">
              <div style="color:rgba(44, 54, 74, 1);font-size: 15px;font-weight: bold;">{{item.name}}</div>
              <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.phone}}</div>
              <div style="color:rgba(179, 184, 193, 1);font-size:11px;">{{item.loginTime}}</div>
            </div>
            <div>
              <span style="color:rgba(44, 54, 74, 1);font-size:18px;margin-right:9px;font-weight: bold;">￥{{item.price}}</span>
            </div>
          </div>
        </div>
        <div v-if="smsList.length==0" style="text-align: center;">
          <img style="width:40%;margin-top:50px;" src="../assets/image/no-data.png" alt="">
        </div>
      </div>
    </div>
    
    <div @click="showShare" style="width: 52px;height: 52px;border-radius: 26px;background:rgba(120, 86, 255, 1);position: fixed;right: 24px;bottom:80px;text-align: center;line-height: 60px;">
      <a-icon type="share-alt" style="color:#fff;font-size:26px;" />
    </div>
    <van-action-sheet v-model="ifShowSence" title="分享">
      <div style="padding-top:16px;">
        <a-row>
          <a-col :span="8">
            <div style="text-align: center">
              <div @click="copyUrl" style="display: inline-block;width: 60px;height: 60px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);border-radius: 30px;text-align: center;line-height: 60px;">
                <img src="../assets/image/copy-url.svg" style="width: 24px;" alt="">
              </div>
              <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin:12px 0 25px">
                <span @click="copyUrl">复制链接</span>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div style="text-align: center">
              <div @click="createPoster" style="display: inline-block;width: 60px;height: 60px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);border-radius: 30px;text-align: center;line-height: 60px;">
                <img src="../assets/image/creat-code.svg" style="width: 24px;" alt="">
              </div>
              <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin:12px 0 25px">
                <span @click="createPoster">生成海报</span>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div style="text-align: center">
              <div @click="creatCode" style="display: inline-block;width: 60px;height: 60px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);border-radius: 30px;text-align: center;line-height: 60px;">
                <img src="../assets/image/my-code.svg" style="width: 24px;" alt="">
              </div>
              <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin:12px 0 25px">
                <span @click="creatCode">我的二维码</span>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="ifShowCode" title="我的二维码">
      <div style="text-align: center;margin:0px 0 0px;">
        <canvas  ref="qrcode"></canvas >
      </div>
    </van-action-sheet>
    <div v-if="ifShowPoster" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div style="position: absolute;width: 100%;height: 100%;"></div>
        <a-icon @click="hidePoster" style="color:#000;font-size:20px;position: absolute;right:16px;top:12px;z-index: 1000000;" type="close" />
        <canvas ref="posterCanvas" style="width: 100%;float: left;z-index: 1000;"></canvas>
        <!-- <canvas ref="erweimaCanvas" :style="{width: pic2.width,zIndex: 1001,height:pic2.height,position: 'absolute',left:pic2.positionX+'px',top:pic2.positionY+'px'}"></canvas> -->
        <div @click="lastPoster" v-if="currentIndex!=0" style="display: inline-block;width:32px;height: 32px;font-size: 16px;color:rgba(44, 52, 76, 1);text-align: center;position: absolute;left:12px;top:50%;background:rgba(255, 255, 255, 0.98);border-radius:16px;line-height: 32px;">
          <a-icon type="left" />
        </div>
        <div @click="nextPoster" v-if="currentIndex!=posterList.length-1" style="display: inline-block;width:32px;height: 32px;font-size: 16px;color:rgba(44, 52, 76, 1);text-align: center;position: absolute;right:12px;top:50%;background:rgba(255, 255, 255, 0.98);border-radius:16px;line-height: 32px;">
          <a-icon type="right" />
        </div>
      </div>
      <span @click="downloadPoster" style="display: inline-block;width:48px;height:48px;background:#fff;position: fixed;left:50%;bottom:30px;text-align: center;z-index: 1000000;border-radius: 24px;line-height: 48px;font-size:24px;margin-left:-24px;color:#000;box-shadow: rgba(44, 54, 74, 0.16) 0px 5px 28px 0px;">
        <a-icon type="download" />
      </span>
    </div>
    <a-input id="copyHtml1" style="position: fixed;left: 10000;top:10000;z-index: -199;"/>
    <div v-if="ifshowStatus" style="height: 40px;line-height: 40px;text-align: center;padding: 0 16px;position: fixed;width:100%;left:0;bottom:50px;">
      <div style="border-radius: 8px;background:rgba(44, 54, 74, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);height: 40px;color:#fff;font-size:13px">{{ statusText }}</div>
    </div>
    <img :src="currentPostUrl" @load="onImageLoad" style="display: none;"/>

  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
import QRCode from 'qrcode';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        locale,
        zhCN,
        commission: '',
        smsList: [
          // {
          //   amount: 26.96,
          //   loginTime: '2024-08-29 11:27:29',
          //   name: '徐杨军',
          //   phone: '18000000000',
          //   id: '',
          //   avatar: '',
          // },
        ],
        starText: '全部状态',
        starId: null,
        ifShowSence: false,
        ifShowCode: false,
        statusText: '',
        ifshowStatus: false,
        ifShowPoster: false,
        posterList: [],
        currentPostUrl: '',
        currentIndex: 0,
        posterCanvas: '',
        imageUrl: '',
        imageDimensions: null,
        screenWidth: 0, // 用于存储屏幕宽度
        screenHeight: 0, // 用于存储屏幕高度
        pic1: {
          width: 0,
          height: 0,
          positionX: 0,
          positionY: 0
        },
        pic2: {
          width: 0,
          height: 0,
          positionX: 0,
          positionY: 0
        },
        codeUrl: '',
        device: ''
      }
  },
  methods: {
    moment,
    goback() {
      this.$router.go(-1); 
    },
    querySmsList() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/myShareList', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.commission = response.data.data
          this.smsList = response.data.data.withdrawalList
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    handleScroll() {
      // const { scrollTop, scrollHeight, clientHeight } = event.target;
      // if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
      //     if(this.totalNum != this.smsList.length){
      //         this.current ++
      //         this.querySmsList()
      //     } 
      // }
    },
    onSelect(action) {
      this.current = 1
      this.starText = action.text
      this.starId = action.id
      this.querySmsList()
    },
    withdrawal() {
      this.$router.push({
        path: '/withdrawal',
        query:{
          amount: this.commission.total,
          date: this.commission.lastTime,
        }
      });
    },
    showShare() {
      this.ifShowSence = true
    },
    creatCode() {
      this.ifShowSence = false
      this.ifShowCode = true
      let userId = localStorage.getItem('userid')
      setTimeout(() => {
        const url = 'https://www.anybridges.com?userid='+userId; // 替换为你的链接
        const qrcodeCanvas = this.$refs.qrcode;
        // 清空之前的二维码
        const context = qrcodeCanvas.getContext('2d');
        context.clearRect(0, 0, qrcodeCanvas.width, qrcodeCanvas.height);
        // 生成二维码
        QRCode.toCanvas(qrcodeCanvas, url, { width: 200 }, (error) => {
          if (error) {
            console.error('生成二维码时出错:', error);
            this.$toast('生成二维码时出错')
          } else {
            console.log('二维码生成成功');
          }
        });
      }, 100);
    },
    copyUrl() {
      this.ifShowSence = false
      let userId = localStorage.getItem('userid')
      let url = 'https://www.anybridges.com/?userid='+userId;
      let text = url
      let input = document.getElementById("copyHtml1");
      input.value = text; 
      input.select(); 
      document.execCommand("copy"); 
      this.statusText = '链接已复制，快去分享吧'
      this.ifshowStatus = true
      setTimeout(()=>{
        this.ifshowStatus = false
      },1500)
    },
    createPoster() {
      this.ifShowSence = false
      this.ifShowPoster = true
      setTimeout(()=>{
        // 获取 Canvas 元素的引用
        this.posterCanvas = this.$refs.posterCanvas;
        // 设置 Canvas 元素的宽度和高度
        this.posterCanvas.width = this.screenWidth;
        this.posterCanvas.height = this.screenHeight;
        this.generatePoster()
      },200)
    },
    queryPoster() {
      this.axios( 
        {
          method:'get',
          url:'/api/project/poster', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.posterList = response.data.data
          if(this.posterList.length > 0){
            this.currentPostUrl = this.posterList[0]
          }
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    queryCode() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/qrcode', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.codeUrl = 'data:image/png;base64,'+response.data.data.qrcode
          console.log(this.codeUrl)
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    generatePoster() {
      console.log("执行下一张操作")
      const context = this.posterCanvas.getContext('2d');
      context.fillStyle = 'rgba(255, 255, 255, 0)';
      context.fillRect(0, 0, this.posterCanvas.width, this.posterCanvas.height);

      const image = new Image();
      image.src = this.currentPostUrl; // 替换为你的图片链接
      // image.crossOrigin = 'anonymous'; // 设置 CORS 属性
      image.onload = () => {
        console.log("生成海报")
        context.drawImage(image, this.pic1.positionX, this.pic1.positionY, this.pic1.width, this.pic1.height);
      };

      // let userId = localStorage.getItem('userid')
      // setTimeout(() => {
      //   const url = 'https://www.anybridges.com/index?userId='+userId; // 替换为你的链接
      //   const qrcodeCanvas = this.$refs.erweimaCanvas;
      //   // 清空之前的二维码
      //   const context = qrcodeCanvas.getContext('2d');
      //   context.clearRect(0, 0, qrcodeCanvas.width, qrcodeCanvas.height);
      //   // 生成二维码
      //   QRCode.toCanvas(qrcodeCanvas, url, { width: this.pic2.width }, (error) => {
      //     if (error) {
      //       console.error('生成二维码时出错:', error);
      //       this.$toast('生成二维码时出错')
      //     } else {
      //       console.log('二维码生成成功');
      //     }
      //   });
      // }, 100);
      setTimeout(()=>{
        const image2 = new Image();
        image2.src = this.codeUrl; // 替换为你的图片链接
        image2.onload = () => {
          context.drawImage(image2, this.pic2.positionX, this.pic2.positionY, this.pic2.width, this.pic2.height);
          console.log("生成创建海报二维码")
        };
      },500)
    },
    downloadPoster() {
      // if(this.device == 'iOS'){
      //   // 获取 Canvas 元素的 Data URL
      //   const dataURL = this.posterCanvas.toDataURL('image/png');
      //   // 创建一个临时链接
      //   const link = document.createElement('a');
      //   link.href = dataURL;
      //   link.download = 'poster.png'; // 设置下载的文件名
      //   document.body.appendChild(link);
      //   link.click(); // 触发下载
      //   document.body.removeChild(link); // 清理临时链接
      // }else{
      //   const dataURL = this.posterCanvas.toDataURL('image/png');
      //   // 将 dataURL 转换为 Blob
      //   fetch(dataURL)
      //     .then(res => res.blob())
      //     .then(blob => {
      //       const url = URL.createObjectURL(blob);
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.download = 'poster.png'; // 设置下载的文件名
      //       document.body.appendChild(link);
      //       link.click(); // 触发下载
      //       document.body.removeChild(link); // 清理临时链接
      //       URL.revokeObjectURL(url); // 释放 Blob URL
      //     })
      //     .catch(err => {
      //       console.error('下载失败:', err);
      //     });
      //   }
      const dataURL = this.posterCanvas.toDataURL('image/png');
      // 将 dataURL 转换为 Blob
      fetch(dataURL)
        .then(res => res.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'poster.png'; // 设置下载的文件名
          document.body.appendChild(link);
          link.click(); // 触发下载
          document.body.removeChild(link); // 清理临时链接
          URL.revokeObjectURL(url); // 释放 Blob URL
        })
        .catch(err => {
          console.error('下载失败:', err);
        });
    },
    onImageLoad(event) {
      const img = event.target;
      this.imageDimensions = {
        width: img.width,
        height: img.height,
      };
      if(this.imageDimensions.width/this.imageDimensions.height > this.screenWidth/this.screenHeight){
        this.pic1.width = this.screenWidth
        this.pic1.height = this.screenWidth*this.imageDimensions.height/this.imageDimensions.width
        this.pic1.positionX = 0
        this.pic1.positionY = (this.screenHeight - this.pic1.height)/2

        this.pic2.width = 280*this.pic1.width/1204
        this.pic2.height = 280*this.pic1.height/2140
        
        this.pic2.positionX = this.pic1.width*775/1204
        this.pic2.positionY = this.pic1.height*1610/2140 + this.pic1.positionY
      }else{
        this.pic1.height = this.screenHeight
        this.pic1.width = this.screenHeight*this.imageDimensions.width/this.imageDimensions.height
        this.pic1.positionY = 0
        this.pic1.positionX = (this.screenWidth - this.pic1.width)/2

        this.pic2.width = 280*this.pic1.width/1204
        this.pic2.height = 280*this.pic1.height/2140

        this.pic2.positionX = this.pic1.width*775/1204 + this.pic1.positionX
        this.pic2.positionY = this.pic1.height*1610/2140
      }
    },
    hidePoster() {
      this.ifShowPoster = false
    },
    lastPoster() {
      this.currentIndex --
      this.currentPostUrl = this.posterList[this.currentIndex]
      this.generatePoster()
    },
    nextPoster() {
      this.currentIndex ++
      this.currentPostUrl = this.posterList[this.currentIndex]
      this.generatePoster()
    },
  },
  mounted() {
    this.querySmsList()
    this.queryPoster()
    this.queryCode()
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log(this.screenWidth)
    console.log(this.screenHeight)
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      this.device = 'iOS';
    } else {
      this.device = 'Android';
    } 
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 44px 0 0;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
</style>
