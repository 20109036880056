<template>
  <div class="index" @scroll="handleScroll" @click="hideMember">
    <div class="banner"></div>
    <div :class="ifShowTitle?'head-banner2':'head-banner1'">
      <a-icon @click="goback" style="font-size:20px;color:#fff;margin: 20px 0 0 16px;float: left;" type="left" />
      <img @click="goShare" style="width: 20px;float: right;margin: 20px 16px 0 0;" src="../assets/image/share.png" alt="">
      <div v-if="ifShowTitle" style="color:#fff;font-size:15px;text-align: center;margin-top:20px;">
        <span style="display: inline-block;width:28px;height:28px;border-radius: 14px;background:rgba(8, 7, 7, 1);margin-right:8px;">
          <img style="width:22px;border-radius: 11px;" :src="robotDel.image" alt="">
        </span>
        <span>{{ robotDel.name }}</span>
      </div>
    </div>
    <div style="width: 100%;position: absolute;left:0;top:70px;z-index:1">
      <div style="display: flex;z-index: 1;">
        <img style="width: 93px;z-index: 1;" :src="robotDel==null?'../assets/image/person.png':robotDel.image" alt="">
        <div style="flex:1;">
          <div style="color:rgba(255, 255, 255, 1);font-size:18px;font-weight: bold;padding-top:12px">{{robotDel==null?'场景名称':robotDel.name}}</div>
          <div style="color:rgba(255, 255, 255, 1);font-size:13px;margin-top:10px;">{{robotDel==null?'一句话概述，机器人简介一句话概述，机器人简介一句话概述，机器人简介':robotDel.description}}</div>
        </div>  
      </div>
    </div>
    <div style="height: 80px;background:#fff;width: 100%;border-radius: 16px 16px 0 0;z-index: 100;padding: 12px 16px;margin-top:-35px;z-index: 100;position: absolute;top:210px;">
      <div style="width:100%;position: relative;">
        <a-date-picker :locale="locale" @change="onChange" style="border: none;opacity: 0;z-index: 10;position: absolute;left:0;top:0;" />
        <div v-if="month!=''" style="position: absolute;top:0px;left:10px;font-size:20px;z-index: 1;">
          <span style="color:rgba(44, 54, 74, 1);font-size:40px;font-weight: bold;">{{month}}</span>
          <span style="color:rgba(44, 54, 74, 1);font-size: 14px;margin:0 8px">/</span>
          <span style="color:rgba(44, 54, 74, 1);font-size: 16px;font-weight: bold;margin-right:6px;">{{ day }}</span>
          <a-icon type="caret-down" />
        </div>
        <div v-if="month==''" style="position: absolute;top:0px;left:10px;font-size:16px;z-index: 1;color:#ccc">请选择日期</div>
      </div>
    </div>
    <div v-if="!hasData" style="background: #fff;text-align: center;padding: 0 13px 0 18px;">
      <img style="width: 180px;margin-top: 80px;" src="../assets/image/no-data.png" alt="">
      <div style="width: 100%;background: #FCECF6;border-radius: 8px;margin-top: 8px;padding: 16px 48px;color:rgba(103, 110, 125, 1);font-size:13px;text-align: left;">
        {{advertisement}}
      </div>
    </div>
    <div v-if="hasData" style="background: #fff;padding: 0 15px 0 16px;margin-top: 60px;">
      <div>
        <div style="display: inline-block;height: 28px;background: #E8E9EC;border-radius: 8px;padding: 0 3px;">
          <span @click="selectCdr(1)" :class="selectedCdr==1?'active-cdr':'common-cdr'">全部话单</span>
          <span @click="selectCdr(2)" :class="selectedCdr==2?'active-cdr':'common-cdr'">已接通</span>
        </div>
        <!-- <span style="float: right;color:rgba(120, 86, 255, 1);font-size:13px;margin-top:10px;">{{totalNum}}条</span> -->
        <div style="float: right;position: relative;margin-top:6px;" @click="showFilter">
          <a-icon type="funnel-plot" style="font-size:16px;" />
          <span style="width: 14px;height: 14px;border-radius: 7px;display: inline-block;color: #fff;font-weight: bold;background: rgba(120, 86, 255, 1);text-align: center;line-height: 14px;position: absolute;right: -5px;top:-7px;font-size:10px;">{{ filterNum }}</span>
        </div>
      </div>
      <div style="margin-top: 15px;position: relative;">
        <div v-if="robotDel.isOwner" style="display: inline-block;">
          <div @click.stop="chooseMember" style="display: inline-block;color:rgba(103, 110, 125, 1);font-size:13px;">
            <span>成员筛选</span>
            <a-icon style="margin-left: 6px;" type="caret-down" />
          </div>
          <span style="color:rgba(179, 184, 193, 1);font-size:13px;margin-left:24px;">已选{{selectedMember.length}}人</span>
          <div @click.stop="chooseMember" v-if="ifShowMember" style="width: 128px;max-height:200px;min-height:60px;box-shadow: 0px 0px 56px 0px rgba(44, 54, 74, 0.24);background:#fff;position: absolute;top:28px;left:0px;z-index:1000;border-radius: 12px;overflow-y: auto;padding:5px 0px 5px 40px;font-size:13px;font-weight:bold;">
            <div @click="selectMember(index)" style="position: relative;height:40px;padding:10px 0;" :class="item.selected?'selected-member':''" v-for="(item,index) in memberList" :key="index">
              <img v-if="item.selected" style="width:19px;position: absolute;left:-20px;top:10px;" src="../assets/image/member-check.svg" alt="">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
        <span style="float: right;color:rgba(120, 86, 255, 1);font-size:13px">{{totalNum}}条</span>
      </div>
      <div style="clear: both;"></div>
      <div style="margin-top: 8px;padding-bottom: 100px;position: relative;">
        <a-input v-model="searchKeyWord" placeholder="输入号码/对话内容" style="border:none;background:rgba(243, 245, 248, 1);text-align: center;height: 32px;border-radius: 8px;"/>
        <a-icon @click="onSearch" type="search" style="color: rgba(0,0,0,.45);position: absolute;right:10px;top:10px;" />
        <div @click="viewDel(item.id,item.status)" style="height: 40px;width: 100%;display: flex;margin-top:15px" v-for="(item,index) in callRecordList" :key="index">
          <img style="width: 32px;height:32px;float: left;margin-top:4px;margin-right: 8px;" src="../assets/image/user.png" alt="">
          <div style="display: inline-block;float: left">
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold">{{item.name}}</div>
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.callTime}}</div>
          </div>
          <div style="flex: 1;text-align: right;padding-top:12px">
            <span style="color:rgba(103, 110, 125, 1);font-size:13px;">{{item.duration}}</span>
            <a-icon style="color:#D1D3D8;font-size:18px;float: right;margin-top:2px;" type="caret-right" />
          </div>
        </div>
        <div v-if="callRecordList.length==0" style="text-align:center;;margin-top:70px;">
          <img style="width:200px" src="../assets/image/no-data.png" alt="">
        </div>
      </div>
    </div>
    <div v-if="ifshowStatus" style="height: 40px;line-height: 40px;text-align: center;padding: 0 16px;position: fixed;width:100%;left:0;bottom:95px;">
      <div style="border-radius: 8px;background:rgba(44, 54, 74, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);height: 40px;color:#fff;font-size:13px">{{ statusText }}</div>
    </div>
    <div style="width: 100%;position: fixed;bottom: 24px;left:0;padding: 0 16px;">
       <div v-if="waitingCallCount==0" style="height: 60px;width: 100%;display: flex">
        <div  @click="goSort" style="height: 60px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);width: 60px;border-radius: 30px;background:rgba(255, 104, 205, 1);margin-right:12px;">
          <div style="color:#fff;font-size:20px;font-weight: bold;margin-top:6px;">{{waitingCheckCount}}</div>
          <div style="color:#fff;font-size: 12px;">未读</div>
        </div>
        <div @click="importData" style="height: 60px;flex:1;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);color:rgba(44, 54, 74, 1);font-size:13px;font-weight: bold;border-radius:30px;padding: 21px 0 0 0;text-align: center;background: #fff;">
          <a-icon style="font-size:16px" type="cloud-upload" />
          <span style="margin-left: 8px;">开始外呼推广</span>
        </div>
      </div>
      
      <div style="height: 60px;width: 100%;display: flex;" v-if="waitingCallCount!=0&&callStatus==2">
        <div  @click="goSort" style="height: 60px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);width: 60px;border-radius: 30px;background:rgba(255, 104, 205, 1);margin-right:12px;">
          <div style="color:#fff;font-size:20px;font-weight: bold;margin-top:6px;">{{waitingCheckCount}}</div>
          <div style="color:#fff;font-size: 12px;">未读</div>
        </div>
        <div style="flex: 1;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);height: 60px;border-radius: 30px;display: flex;padding: 12px 15px;background:#fff;">
          <div style="flex: 1;margin-right:8px;">
            <div>
              <span style="color:rgba(74, 179, 100, 1);font-size:10px;font-weight: bold;">正在呼叫...</span>
              <van-popover
                v-model="showPopover"
                trigger="click"
                :actions="actions"
                @select="onSelect"
                style="float: right;"
                placement="top"
              >
                <template #reference>
                  <span style="display: inline-block;width:32px;height:20px;text-align: center;line-height: 20px;border-radius: 10px;background:rgba(120, 86, 255, 0.16);color:rgba(120, 86, 255, 1);font-size:10px;">{{speedText}}</span>
                </template>
              </van-popover>
            </div>
            <div style="color:rgba(44, 54, 74, 1);font-size:12px;">{{waitingCallCount}}个号码排队中</div>
          </div>
          <div @click="stopCall" style="display: inline-block;width:118px;background:rgba(120, 86, 255, 1);border-radius: 19px;height: 38px;padding:9px 21px">
            <a-icon style="color:#fff;font-size:16px;" type="pause" />
            <span style="color:#fff;font-size:13px;font-weight: bold;margin-left:6px;">暂停呼叫</span>
          </div>
        </div>
      </div>
      
      <div style="height: 60px;width: 100%;display: flex;" v-if="waitingCallCount!=0&&callStatus==1">
        <div  @click="goSort" style="height: 60px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);width: 60px;border-radius: 30px;background:rgba(255, 104, 205, 1);margin-right:12px;">
          <div style="color:#fff;font-size:20px;font-weight: bold;margin-top:6px;">{{waitingCheckCount}}</div>
          <div style="color:#fff;font-size: 12px;">未读</div>
        </div>
        <div style="flex: 1;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);height: 60px;border-radius: 30px;display: flex;padding: 12px 15px;background:#fff">
          <div style="flex: 1">
            <div style="color:rgba(255, 141, 26, 1);font-size:10px;font-weight: bold;">任务已暂停...</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:12px;">{{waitingCallCount}}个号码排队中</div>
          </div>
          <div @click="importData" style="display: inline-block;width:38px;background:rgba(44, 54, 74, 1);border-radius: 19px;height: 38px;text-align: center;margin-right:6px;">
            <div>
              <a-icon style="color:#fff;font-size:14px;float:left;margin-left: 12px;margin-top:4px;" type="cloud-upload" />
            </div>
            <div style="clear: both;"></div>
            <div style="color:#fff;font-size:10px;margin-left:2px;">上传</div>
          </div>
          <div @click="switchLine" style="display: inline-block;width:38px;background:rgba(246, 76, 79, 1);border-radius: 19px;height: 38px;text-align: center;">
            <div>
              <a-icon style="color:#fff;font-size:14px;float:left;margin-left: 12px;margin-top:4px;" type="swap" />
            </div>
            <div style="clear: both;"></div>
            <div style="color:#fff;font-size:10px;margin-left:2px;">换线</div>
          </div>
          <div @click="continueCall" style="display: inline-block;width:38px;background:rgba(120, 86, 255, 1);border-radius: 19px;height: 38px;text-align: center;margin-left: 6px;">
            <div>
              <a-icon style="color:#fff;font-size:14px;float:left;margin-left: 12px;margin-top:4px;" type="caret-right" />
            </div>
            <div style="clear: both;"></div>
            <div style="color:#fff;font-size:10px;margin-left:2px;">继续</div>
          </div>
        </div>
      </div>
      <div style="height: 60px;width: 100%;display: flex;" v-if="waitingCallCount!=0&&callStatus==3">
        <div  @click="goSort" style="height: 60px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);width: 60px;border-radius: 30px;background:rgba(255, 104, 205, 1);margin-right:12px;">
          <div style="color:#fff;font-size:20px;font-weight: bold;margin-top:6px;">{{waitingCheckCount}}</div>
          <div style="color:#fff;font-size: 12px;">未读</div>
        </div>
        <div style="flex: 1;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);height: 60px;border-radius: 30px;display: flex;padding: 12px 15px;background:#fff">
          <div style="flex: 1">
            <div style="display:flex">
              <div style="display: inline-block;color:rgba(246, 76, 79, 1);font-size:10px;font-weight: bold;padding-top:4px;">余额不足! 已暂停</div>
              <div style="flex: 1">
                <van-popover
                    v-model="showPopover"
                    trigger="click"
                    :actions="actions"
                    @select="onSelect"
                    style="float: left;margin-left:12px;"
                    placement="top"
                  >
                    <template #reference>
                      <span style="display: inline-block;width:32px;height:20px;text-align: center;line-height: 20px;border-radius: 10px;background:rgba(120, 86, 255, 0.16);color:rgba(120, 86, 255, 1);font-size:10px;">{{speedText}}</span>
                    </template>
                  </van-popover>
              </div>
            </div>
            <div style="color:rgba(44, 54, 74, 1);font-size:12px;">{{waitingCallCount}}个号码排队中</div>
          </div>
          
          <div @click="switchLine" style="display: inline-block;width:118px;background:rgba(246, 76, 79, 1);border-radius: 19px;height: 38px;padding:9px 21px">
            <a-icon style="color:#fff;font-size:16px;" type="swap" />
            <span style="color:#fff;font-size:13px;font-weight: bold;margin-left:6px;">切换换线</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="importVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="height: 258px;background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;">
            <span>告诉我您想联系哪些号码？</span>
            <a-icon @click="closeImport" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <a-row style="margin-top: 20px;">
            <a-col :span="12" style="padding-right: 10px;">
              <div @click="manuallyAdd" style="width: 100%;height: 172px;background: rgba(121, 72, 234, 0.12);border-radius: 10px;padding: 20px 16px;">
                <div style="color:rgba(121, 72, 234, 1);font-weight: bold;">手动添加</div>
                <div style="color:rgba(103, 110, 125, 1);font-size: 13px;margin-top: 8px;">一个个号码手动输入，适合号码较少的情况</div>
              </div>
            </a-col>
            <a-col :span="12" style="padding-left: 10px;">
              <div @click="uploadPc" style="width: 100%;height: 172px;background: rgba(179, 184, 193, 0.16);border-radius: 10px;padding: 20px 16px;">
                <div style="color:rgba(44, 54, 74, 1);font-weight: bold;">批量导入</div>
                <div style="color:rgba(103, 110, 125, 1);font-size: 13px;margin-top: 8px;">需在pc端操作，复制链接在浏览器打开，上传文件，适合号码较多的情况</div>
                <div style="color:rgba(212, 48, 48, 1);font-size:13px;margin-top: 8px;">*此功能可删除号码</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div v-if="numberVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 0 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;padding-right: 24px;">
            <span>添加号码</span>
            <a-icon @click="closeNumber" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <div style="max-height: 200px;overflow: auto;padding-top:8px">
            <div v-for="(item,index) in numberList" :key="index" style="margin-bottom: 12px;width:100%;display: flex;">
              <input v-model.trim="item.phone" style="height: 40px;border: none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;padding:0 12px;flex: 1;" type="number">
              <a-icon @click="deleteNumber(index)" style="color:rgba(246, 76, 79, 1);font-size: 22px;margin-left:9px;margin-top:8px;margin-right: 20px" type="minus-circle" />
            </div>
          </div>
          <div style="text-align: center;margin: 16px 0;padding-right: 24px;">
            <img @click="addNumber" style="width:32px;" src="../assets/image/add.svg" alt="">
          </div>
          <div @click="sureUpload" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-right: 24px;">确认上传</div>
        </div>
      </div>
    </div>
    <div v-if="uploadVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>请打开电脑上传</span>
            <a-icon @click="closeUpload" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <div style="color: rgba(103, 110, 125, 1);font-size:13px;margin: 20px 0">
            复制url地址，通过电脑浏览器打开，单次最大10000+号码上传，支持csv,xls,txt,xlsx等格式
          </div>
          <div @click="copyUrl" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;">复制url地址</div>
        </div>
      </div>
      <a-input id="copyHtml1" readonly="readonly" style="position: fixed;left: 10000;top:10000;"/>
    </div>
    <div v-if="filterVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>筛选通话列表</span>
            <a-icon @click="closeFilter" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px">标签多选</div>
          <div>
            <a-select
              mode="multiple"
              size="default"
              placeholder="请选择"
              style="width: 100%;margin: 12px 0 20px 0;"
              v-model="selectedTag"
            >
              <a-select-option v-for="(item,index) in tagList" :key='index' :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px">通话时长范围</div>
          <div style="color:rgba(44, 54, 74, 1);font-size:15px;margin-top:12px">
            <span>大于</span>
            <a-input type="number" v-model="minNum" placeholder="请输入数值" style="width: 160px;border:none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;height: 40px;margin:0 12px;"/>
            <span>秒；</span>
          </div>
          <div style="color:rgba(44, 54, 74, 1);font-size:15px;margin-top:12px">
            <span>小于</span>
            <a-input type="number" v-model="maxNum" placeholder="请输入数值" style="width: 160px;border:none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;height: 40px;margin:0 12px;"/>
            <span>秒；</span>
          </div>
          <div @click="sureFilter" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:24px;">确认</div>
        </div>
      </div>
    </div>
    <!-- <van-popup v-model="lineVisible" position="bottom;" :style="{ height: '50%' }" round > -->
    <div v-if="lineVisible" style="position: fixed;width:100%;height:100%;top:0;z-index: 2000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>选择线路</span>
            <a-icon @click="closeLine" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <div style="color: rgba(103, 110, 125, 1);font-size:13px;margin: 22px 0 16px">
            <div>
              <div style="display: inline-block;">
                <div>
                  <span style="color:rgba(44, 54, 74, 1);font-size:18px;font-weight: bold">{{amount}}</span>
                  <span style="color:rgba(44, 54, 74, 1);font-size:12px;font-weight: bold">元</span>
                </div>
                <div>账户余额</div>
              </div>
              <span @click="goService" style="display: inline-block;width:80px;height: 40px;line-height: 40px;text-align: center;border-radius: 8px;background: rgba(120, 86, 255, 1);float: right;color:#fff;font-size:15px;">充值</span>
            </div>
            <div style="margin-top:28px;margin-bottom: 17px;">
              <div style="display: inline-block;" @click="selectCity">
                <span style="color:rgba(103, 110, 125, 1);font-size:13px;">{{selectedCityName==''?'选择城市':selectedCityName}}</span>
                <a-icon style="color:rgba(48, 52, 76, 1);font-size:16px;margin-left:4px;" type="caret-down" />
              </div>
              <span style="float: right;color:rgba(179, 184, 193, 1);font-size:13px;">{{currentLine.name}}</span>
            </div>
            <div style="max-height: 355px;overflow-y: auto">
              <div @click="selectLine(item.lineId)" v-for="(item,index) in availableLineList" :key="index" style="height: 68px;border-radius: 8px;padding: 12px 16px;position: relative;margin-bottom:12px" :class="selectedLineId==item.lineId?'line-active':'line-common'">
                <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;">{{item.name}}</div>
                <div style="margin-top:4px">
                  <span style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.description}}</span>
                  <div style="float: right">
                    <span style="color:rgba(246, 76, 79, 1);font-size:16px;font-weight: bold;">{{item.price}}</span>
                    <span style="text-decoration: line-through;color:rgba(179, 184, 193, 1)">{{item.origin_price}}</span>
                    <span style="color:rgba(103, 110, 125, 1);font-size: 12px;">元/分钟</span>
                  </div>
                </div>
                <span v-if="item.currentConcurrencyCount/item.concurrencyCount>1||item.currentConcurrencyCount/item.concurrencyCount==1" style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(246, 76, 79, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">堵</span>
                <span v-if="item.currentConcurrencyCount/item.concurrencyCount>0.6&&item.currentConcurrencyCount/item.concurrencyCount<1" style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(255, 195, 0, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">适</span>
                <span v-if="item.currentConcurrencyCount/item.concurrencyCount<0.6||item.currentConcurrencyCount/item.concurrencyCount==0.6" style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(67, 207, 124, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">闲</span>
              </div>
              <!-- <div style="height: 68px;border-radius: 8px;padding: 12px 16px;position: relative;margin-top:12px;">
                <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;">江苏固话</div>
                <div style="margin-top:4px">
                  <span style="color:rgba(103, 110, 125, 1);font-size:12px;">行业系数-10%</span>
                  <div style="float: right">
                    <span style="color:rgba(246, 76, 79, 1);font-size:16px;font-weight: bold;">0.108</span>
                    <span style="text-decoration: line-through;color:rgba(179, 184, 193, 1)">0.12</span>
                    <span style="color:rgba(103, 110, 125, 1);font-size: 12px;">元/分钟</span>
                  </div>
                </div>
                <span style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(246, 76, 79, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">堵</span>
              </div> -->
              <div style="color:rgba(44, 54, 74, 1);font-size:12px;margin-top:20px;">不支持当前场景</div>
              <div v-for="(item,index) in unAvailableLineList" :key="index" style="height: 68px;border-radius: 8px;padding: 12px 16px;position: relative;background:rgba(179, 184, 193, 0.16);margin-top:12px;opacity: 0.6;">
                <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;">{{item.name}}</div>
                <div style="margin-top:4px">
                  <span style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.description}}</span>
                  <div style="float: right">
                    <span style="color:rgba(246, 76, 79, 1);font-size:16px;font-weight: bold;">{{item.price}}</span>
                    <span style="text-decoration: line-through;color:rgba(179, 184, 193, 1)">{{item.origin_price}}</span>
                    <span style="color:rgba(103, 110, 125, 1);font-size: 12px;">元/分钟</span>
                  </div>
                </div>
                <span v-if="item.currentConcurrencyCount/item.concurrencyCount>1||item.currentConcurrencyCount/item.concurrencyCount==1" style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(246, 76, 79, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">堵</span>
                <span v-if="item.currentConcurrencyCount/item.concurrencyCount>0.6&&item.currentConcurrencyCount/item.concurrencyCount<1" style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(255, 195, 0, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">适</span>
                <span v-if="item.currentConcurrencyCount/item.concurrencyCount<0.6||item.currentConcurrencyCount/item.concurrencyCount==0.6" style="position:absolute;right:0;top:0;width:24px;height:24px;display: inline-block;background:rgba(67, 207, 124, 1);border-radius: 0px 8px 0 8px;color:#fff;text-align: center;line-height: 24px;font-size:12px;">闲</span>
              </div>
            </div>
          </div>
          <div @click="sureLine" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;">确认选择</div>
        </div>
      </div>
    </div>
  <!-- </van-popup> -->
    <van-popup v-model="ifShowCity" round position="bottom" :style="{ height: '40%',width: '100%',bottom:0 }" >
      <van-picker
          title="标题"
          show-toolbar
          :columns="areaNameList"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="changeCity"
      />
      <!-- <div style="height:220px;width:100%;background:red"></div> -->
    </van-popup>
    <div v-if="screenVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;position: relative;">
            <span>绑定手机号码</span>
            <a-icon @click="closeScreen" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
          </div>
          <div style="text-align: center;margin:24px 0 13px">
            <img style="width: 71px;" src="../assets/image/head1.png" alt="">
          </div>
          <div style="text-align: center">
            <img src="../assets/image/ai.png" style="width: 131px;" alt="">
          </div>
          <div style="height: 88px;background: #fff;border-radius: 8px;margin-top: 28px;position: relative;padding: 22px 20px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);">
              <a-input class="custom-input" v-model.trim="phoneNum" type="number" style="border: none;background: none;font-size:18px" placeholder="请输入手机号" />
              <span @click="getCode" style="display: inline-block;width: 263px;height: 48px;line-height: 48px;border-radius: 12px;text-align: center;color:#fff;font-size:16px;background: rgba(120, 86, 255, 1);margin-top:5px;">获取验证码</span>
          </div>
          <!-- <div style="color:rgba(103, 110, 125, 1);font-size: 12px;margin-top:30px;text-align: center;">若手机号未注册，登录后会自动注册</div> -->
        </div>
      </div>
    </div>
    <div v-if="pcVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 24px;position: relative;">
            <span>绑定手机号码</span>
            <a-icon @click="closePc" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px;">请输入验证码</div>
          <div style="margin-top: 12px;">
            <a-row style="text-align: center;">
              <a-col :span="4">
                <a-input ref="myInput1"  @input="onInputChange1" v-model="code1" type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="4">
                <a-input ref="myInput2"  @input="onInputChange2" v-model="code2" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="4">
                <a-input ref="myInput3"  @input="onInputChange3" v-model="code3" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="4">
                <a-input ref="myInput4"  @input="onInputChange4" v-model="code4" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="4">
                <a-input ref="myInput5"  @input="onInputChange5" v-model="code5" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="4">
                <a-input ref="myInput6" v-model="code6" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
            </a-row>
          </div>
          <div @click="loginIn" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:48px">确认</div>
        </div>
      </div>
    </div>
    <div v-if="shareVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>转发链接</span>
            <a-icon @click="closeShare" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <a-input v-model="copyHtml2" id="copyHtml2" readonly="readonly" style="width:100%;height:40px;border-radius:8px;margin:0 0 20px 0;background:rgba(179, 184, 193, 0.16);"/>
          <div @click="copySite" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;">复制url地址</div>
        </div>
      </div>
      
    </div>



    <div v-if="filterVisible2" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>设定未读条件</span>
            <a-icon @click="closeFilter2" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px">标签多选</div>
          <div>
            <a-select
              mode="multiple"
              size="default"
              placeholder="请选择"
              style="width: 100%;margin: 12px 0 20px 0;"
              v-model="selectedTag2"
            >
              <a-select-option v-for="(item,index) in tagList" :key='index' :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px">通话时长范围</div>
          <div style="color:rgba(44, 54, 74, 1);font-size:15px;margin-top:12px">
            <span>大于</span>
            <a-input type="number" v-model="minNum2" placeholder="请输入数值" style="width: 160px;border:none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;height: 40px;margin:0 12px;"/>
            <span>秒；</span>
          </div>
          <div style="color:rgba(44, 54, 74, 1);font-size:15px;margin-top:12px">
            <span>小于</span>
            <a-input type="number" v-model="maxNum2" placeholder="请输入数值" style="width: 160px;border:none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;height: 40px;margin:0 12px;"/>
            <span>秒；</span>
          </div>
          <div @click="sureFilter2" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:24px;">保存设定</div>
        </div>
      </div>
    </div>


  </div>
</template>
  
  <script>
  import moment from 'moment';
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
  import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
  // import { Toast } from 'vant';
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    data() {
        return {
          locale,
          zhCN,
          dataList: [1],
          selectedCdr: 1,
          importVisible: false,
          numberVisible: false,
          numberList: [
            {
              phone: ''
            }
          ],
          uploadVisible: false,
          uploadState: 1,
          statusText: '',
          ifshowStatus: false,
          filterVisible: false,
          lineVisible: false,
          year: '',
          month: '',
          day: '',
          ifShowCity: false,
          columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
          cityName: '',
          id: '',
          robotDel: null,
          hasData: false,
          screenVisible: false,
          pcVisible: false,
          phoneNum: '',
          code1: '',
          code2: '',
          code3: '',
          code4: '',
          code5: '',
          code6: '',
          waitingCheckCount: 0,
          isLogin: false,
          maxNum: null,
          minNum: null,
          tagList: [],
          selectedTag: [],
          filterNum: 0,
          searchKeyWord: '',
          callRecordList: [],
          totalNum: 0,
          waitingCallCount: 0,
          callStatus: null,
          areaNameList: [],
          areaIdList: [],
          selectedCityName: '',
          selectedCityId: '',
          currentLine: '',
          unAvailableLineList: [],
          availableLineList: [],
          selectedLineId: '',
          amount: 0,
          currentPage: 1,
          shareVisible: false,
          copyHtml2: '',
          advertisement: '',
          timer: null,
          ifShowTitle: false,
          showPopover: false,
          // 通过 actions 属性来定义菜单选项
          actions: [
            { 
              text: '10x',
              name: '10x',
              id: 10 
            }, 
            { 
              text: '5x',
              name: '5x',
              id: 5 
            }, 
            { 
              text: '2x',
              name: '2x',
              id: 2
            },
            { 
              text: '1x倍速',
              name: '倍速',
              id: 1
            }
          ],
          speedText: '倍速',
          speedId: 1,
          memberList: [
         
          ],
          selectedMember: [
            
          ],
          ifShowMember: false,
          avatar: '',
          filterVisible2: false,
          maxNum2: null,
          minNum2: null,
          selectedTag2: [],
          filterNum2: 0,
          userId: '',
          code: '',
          shareUser: ''
        }
    },
    beforeDestroy() {
      this.stopRefreshing();
    },
    methods: {
      moment,
      onChange(date, dateString) {
        let str = dateString.split("-")
        console.log(str)
        this.year = str[0]
        this.month = str[1]
        this.day = str[2]
        this.queryIfHasData()
        this.currentPage = 1
        this.queryCallLog()
      },
      selectCdr(type) {
        this.selectedCdr = type
        this.currentPage = 1
        this.queryCallLog()
      },
      onSearch() {
        console.log("执行搜索功能")
        this.currentPage = 1
        this.queryCallLog()
      },
      importData() {
        if(this.isLogin){
          this.importVisible = true
        }else{
          this.screenVisible = true
        }
      },
      closeImport() {
        this.importVisible = false
      },
      manuallyAdd() {
        this.importVisible = false
        this.numberVisible = true
      },
      closeNumber() {
        this.numberVisible = false
      },
      addNumber() {
        this.numberList.push({
          phone: ''
        })
      },
      sureUpload() {
        let arr = []
        for(let i = 0; i < this.numberList.length; i++){
          arr.push(this.numberList[i]['phone'])
        }
        this.axios.post('/api/callData/manualAdd', {
          id: this.id,
          phoneList: arr
        })
        .then(response=> {
          if(response.data.code == 0){
            this.numberVisible = false
            this.numberList = []
            this.queryWaitingCallCount()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      uploadPc() {
        this.importVisible = false
        this.uploadVisible = true
      },
      closeUpload() {
        this.uploadVisible = false
      },
      copyUrl() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/waitingCallToken', 
            params: {
              id: this.id
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            let token = response.data.data.waitingCallToken
            let url = ''
            let protocol = window.location.protocol;
            let host = window.location.host;
            url = protocol + '//' + host + '/#/robotTask?token='+token
            console.log(url)
            let text = url
            let input = document.getElementById("copyHtml1");
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.uploadVisible = false
            this.shareVisible = false
            this.statusText = '链接已复制，快去分享吧'
            this.ifshowStatus = true
            setTimeout(()=>{
              this.ifshowStatus = false
            },1500)
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      continueCall() {
        this.axios.post('/api/project/updateCallStatus', {
          id: this.id,
          callStatus: 2
        })
        .then(response=> {
          if(response.data.code == 0){
            this.statusText = '任务已继续'
            this.ifshowStatus = true
            setTimeout(()=>{
              this.ifshowStatus = false
            },1500)
            this.callStatus = 2
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      handleChange(value) {
        // console.log(`Selected: ${value}`);
        this.selectedTag = value
        console.log(this.selectedTag)
      },
      goShare() {
        // this.$router.push("/sharePage")
        this.shareVisible = true
        setTimeout(()=>{
          this.queryRobotToken()
        },500)
      },
      queryRobotToken() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/shareProjectToken', 
            params: {
              id: this.id
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            let token = response.data.data.shareProjectToken
            let url = ''
            let protocol = window.location.protocol;
            let host = window.location.host;
            url = protocol + '//' + host + '/#/sharePage?token='+token
            console.log(url)
            let text = url
            let input = document.getElementById("copyHtml2");
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.copyHtml2 = url
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      closeShare() {
        this.shareVisible = false
      },
      copySite() {

        this.shareVisible = false
        this.statusText = '链接已复制，快去分享吧'
        this.ifshowStatus = true
        setTimeout(()=>{
          this.ifshowStatus = false
        },1500)
      },
      deleteNumber(index) {
        if(this.numberList.length == 1){
          return false
        }else{
          this.numberList.splice(index, 1);
        }
      },
      closeLine() {
        this.lineVisible = false
      },
      sureLine() {
        this.lineVisible = false
        this.axios.post('/api/line/changeLine', {
          id: this.id,
          lineId: this.selectedLineId
        })
        .then(response=> {
          if(response.data.code == 0){
            this.$toast('切换成功')
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      goSort() {
        if(this.waitingCheckCount==0){
          this.showFilter2()
        }else{
          this.$router.push({
            path: '/sortList',
            query:{
              id: this.id,
              avatar: this.avatar
            }
          });
        }
      },
      viewDel(id,status) {
        // this.$router.push("/callDel")
        if(status!=4){
          this.$router.push({
            path: '/callDel',
            query:{
              id: id,
            }
          });
        }
       
      },
      selectCity() {
        // this.lineVisible = false
        this.ifShowCity = true
      },
      queryMoney() {
        this.axios( 
          {
            method:'get',
            url:'/api/user/amount', 
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.amount = response.data.data.amount
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      onConfirm(value, index) {
        console.log(`当前值：${value}, 当前索引：${index}`);
        this.ifShowCity = false
        this.selectedCityName = this.areaNameList[index]
        this.selectedCityId = this.areaIdList[index]
        this.queryLineList()
      },
      changeCity(picker, value, index) {
        console.log(`当前值：${value}, 当前索引：${index}`);
        this.cityName = value
        // this.show = false
      },
      onCancel() {
        console.log('取消');
        this.ifShowCity = false
      },
      goback() {
        this.$router.go(-1)
      },
      queryRobotDetail() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/detail', 
            params: {
              id: this.id
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.robotDel = response.data.data
            this.memberList = response.data.data.memberList
            this.avatar = response.data.data.avatar
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      getCurrentDate() {
        const today = new Date();
        this.year = today.getFullYear();
        this.month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以加 1
        this.day = String(today.getDate()).padStart(2, '0'); // 确保是两位数
      },
      queryIfHasData() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/hasData', 
            params: {
              id: this.id,
              date: this.year+'-'+this.month+'-'+this.day
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.hasData = response.data.data.hasData
            console.log(response.data.data)
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      closeScreen() {
        this.screenVisible = false
      },
      sureScreen() {
        this.screenVisible = false
      },
      register() {
        this.screenVisible = true
      },
      getCode() {
        this.axios.post('/api/verifyCode', {
          phone: this.phoneNum.replace(/\s+/g, ''),
        })
        .then(response=> {
          if(response.data.code == 0){
            // this.$message.success('发送成功')
            this.$toast('发送成功')
            this.screenVisible = false
            this.pcVisible = true
            this.$refs.myInput1.focus()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      closePc() {
        this.pcVisible = false
      },
      loginIn() {
        if (localStorage.getItem('token') ==null){
          this.queryCode()
        }else{
          this.axios.post('/api/user/bindPhone', {
            phone: this.phoneNum.replace(/\s+/g, ''),
            verifyCode: this.code1+''+this.code2+''+this.code3+''+this.code4+''+this.code5+''+this.code6,
            userId: this.userId
          })
          .then(response=> {
            if(response.data.code == 0){
              // this.$message.success('登录成功')
              this.isLogin = true
              this.$toast('登录成功')
              this.sessionData('set','token',response.data.data.token);
              this.pcVisible = false
              // location.reload(); 
              this.queryWaitingCheckCount()
              this.queryCallLog()
              this.queryCurrentLile()
            }else{
              this.$toast(response.data.userTip);
            }
          })
          .catch(error=> {
              console.log(error)
          })
        }
        
      },
      queryWaitingCheckCount() {
        // let str = ''
        // console.log(this.selectedTag)
        // if(this.selectedTag.length > 0){
        //   for(let i = 0; i < this.selectedTag.length; i++){
        //     str += this.selectedTag[i]+','
        //   }
        // }
        // str = str.slice(0, -1)
        this.axios( 
          {
            method:'get',
            url:'/api/callData/waitingCheckCount', 
            params: {
              id: this.id,
              callDate: this.year+'-'+this.month+'-'+this.day,
              // durationQ: this.minNum,
              // durationZ: this.maxNum,
              // labels: str
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.waitingCheckCount = response.data.data.count
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      showFilter() {
        this.filterVisible = true
      },
      queryTag() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/labelList', 
            params: {
              id: this.id,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.tagList = response.data.data
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryCallLog() {
        let str = ''
        if(this.selectedMember.length > 0){
          for(let i = 0; i < this.selectedMember.length; i++){
            str += this.selectedMember[i]+','
          }
          str = str.slice(0, -1)
        }

        let str2 = ''
        if(this.selectedTag.length != 0){
          for(let i = 0; i < this.selectedTag.length; i++){
            str2+=this.selectedTag[i]+','
          }
          str2 = str2.slice(0, -1)
        }
        this.axios( 
          {
            method:'get',
            url:'/api/callData/callRecordList', 
            params: {
              current: this.currentPage,
              size: 10,
              id: this.id,
              callDate: this.year+'-'+this.month+'-'+this.day,
              type: this.selectedCdr,
              keyword: this.searchKeyWord,
              userIds: str,
              durationQ: this.minNum==''?null:this.minNum,
              durationZ: this.maxNum==''?null:this.maxNum,
              labels: str2,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.totalNum = response.data.data.total
            if(this.currentPage == 1){
              this.callRecordList = response.data.data.records
            }else{
              this.callRecordList = this.callRecordList.concat(response.data.data.records)
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryWaitingCallCount() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/waitingCallCount', 
            params: {
              id: this.id,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            // console.log(response.data.data)
            this.waitingCallCount = response.data.data.waitingCallCount
            this.callStatus = response.data.data.callStatus
            if(this.callStatus == 3){
              this.statusText = '余额不足，任务已暂停，请充值'
              this.ifshowStatus = true
              setTimeout(()=>{
                this.ifshowStatus = false
              },1500)
            }
            if(response.data.data.callSpeed==1){
              this.speedText = '倍速'
            }else if(response.data.data.callSpeed==2){
              this.speedText = '2x'
            }else if(response.data.data.callSpeed==5){
              this.speedText = '5x'
            }else if(response.data.data.callSpeed==10){
              this.speedText = '10x'
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      stopCall() {
        this.axios.post('/api/project/updateCallStatus', {
          id: this.id,
          callStatus: 1
        })
        .then(response=> {
          if(response.data.code == 0){
            this.statusText = '任务已暂停'
            this.ifshowStatus = true
            setTimeout(()=>{
              this.ifshowStatus = false
            },1500)
            this.callStatus = 1
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      switchLine() {
        this.lineVisible = true
        this.queryMoney()
      },
      queryCity() {
        this.axios( 
          {
            method:'get',
            url:'/api/line/areaList', 
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            // console.log(response.data.data)
            let arr = response.data.data
            for(let i = 0; i < arr.length; i++){
              this.areaNameList.push(arr[i]['name'])
              this.areaIdList.push(arr[i]['id'])
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryCurrentLile() {
        this.axios( 
          {
            method:'get',
            url:'/api/line/currentLine', 
            params: {
              id: this.id,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            // console.log(response.data.data)
            this.currentLine = response.data.data
            this.selectedCityId = response.data.data.area
            this.selectedLineId = response.data.data.lineId
            this.queryLineList()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryLineList() {
        this.axios( 
          {
            method:'get',
            url:'/api/line/lineList', 
            params: {
              id: this.id,
              area: this.selectedCityId
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            // console.log(response.data.data)
            this.availableLineList = response.data.data.availableLineList
            this.unAvailableLineList = response.data.data.unAvailableLineList
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      selectLine(id) {
        this.selectedLineId = id
      },
      // 滚动事件处理函数
      handleScroll(event) {
        // this.ifScreen = false
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        // console.log('scrollTop--'+scrollTop)
        // console.log('scrollHeight--'+ scrollHeight)
        // console.log('clientHeight--'+clientHeight)
        // 当滚动到底部时，距离顶部+自身高度 >= 总高度 - 一定的阈值
        if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
          if(this.totalNum != this.callRecordList.length){
            this.currentPage ++
            this.queryCallLog()
          } 
        }
        console.log(scrollTop)
        if(scrollTop > 125){
          this.ifShowTitle = true
        }else{
          this.ifShowTitle = false
        }
      },
      startRefreshing() {
        this.timer = setInterval(() => {
          this.queryWaitingCheckCount()
          this.queryWaitingCallCount()
          this.queryIfHasData()
        },  10000); // 转换为毫秒
      },
      stopRefreshing() {
        if (this.timer) {
          clearInterval(this.timer); // 清除定时器
          this.timer = null; // 重置定时器 ID
        }
      },
      closeFilter() {
        this.filterVisible = false
      },
      sureFilter() {
        this.currentPage = 1
        this.filterVisible = false
        this.filterNum = 0
        if(this.maxNum != null&&this.maxNum != ''){
          this.filterNum ++
        }
        if(this.minNum != null&&this.minNum != ''){
          this.filterNum ++
        }
        if(this.selectedTag.length !=0){
          this.filterNum ++
        }
        this.queryCallLog()
      },
      hideModel() {
        this.importVisible = false
        this.numberVisible = false
        this.uploadVisible = false
        this.filterVisible = false
        this.lineVisible = false
        this.screenVisible = false
        this.pcVisible = false
        this.shareVisible = false
        this.filterVisible2 = false
      },
      onInputChange1() {
        if(this.code1.length == 1){
          this.$refs.myInput2.focus()
        }
      },
      onInputChange2() {
        if(this.code2.length == 1){
          this.$refs.myInput3.focus()
        }
      },
      onInputChange3() {
        if(this.code3.length == 1){
          this.$refs.myInput4.focus()
        }
      },
      onInputChange4() {
        if(this.code4.length == 1){
          this.$refs.myInput5.focus()
        }
      },
      onInputChange5() {
        if(this.code5.length == 1){
          this.$refs.myInput6.focus()
        }
      },
      goService() {
        window.location.href = 'https://work.weixin.qq.com/kfid/kfc009eca4d2527ca4e';
      },
      onSelect(action) {
        this.axios.post('/api/project/updateCallSpeed', {
          id: this.id,
          callSpeed: action.id
        })
        .then(response=> {
          if(response.data.code == 0){
            this.speedText = action.name
            this.speedId = action.id
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      hideMember() {
        this.ifShowMember = false
      },
      showMember() {
        this.ifShowMember = true
      },
      chooseMember() {
        this.ifShowMember = true
      },
      selectMember(index) {
        if(this.memberList[index]['selected']){
          this.memberList[index]['selected'] = false
        }else{
          this.memberList[index]['selected'] = true
        }
        this.selectedMember = []
        for(let i = 0; i < this.memberList.length; i++){
          if(this.memberList[i]['selected']==true){
            this.selectedMember.push(this.memberList[i]['id'])
          }
        }
        // console.log(this.memberList)
        // console.log(this.selectedMember)
        this.queryCallLog()
        this.$forceUpdate()
      },


      showFilter2() {
        this.filterVisible2 = true
      },
      closeFilter2() {
        this.filterVisible2 = false
      },
      sureFilter2() {
        this.filterVisible2 = false
        this.filterNum2 = 0
        if(this.maxNum2 != null&&this.maxNum2 != ''){
          this.filterNum2 ++
        }
        if(this.minNum2 != null&&this.minNum2 != ''){
          this.filterNum2 ++
        }
        if(this.selectedTag2.length !=0){
          this.filterNum2 ++
        }
        this.updateFilter()
      },
      updateFilter() {
        let str = ''
        if(this.selectedTag2.length != 0){
          for(let i = 0; i < this.selectedTag2.length; i++){
            str+=this.selectedTag2[i]+','
          }
          str = str.slice(0, -1)
        }
        this.axios.post('/api/project/updateCondition', {
          id: this.id,
          durationQ: this.minNum2==''?null:this.minNum2,
          durationZ: this.maxNum2==''?null:this.maxNum2,
          labels: str,
        })
        .then(response=> {
          if(response.data.code == 0){
            this.queryWaitingCheckCount()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      queryFilter() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/queryCondition', 
            params: {
              id: this.id,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            // console.log(response.data.data)
            this.maxNum2 = response.data.data.durationZ
            this.minNum2 = response.data.data.durationQ
            let str = response.data.data.labels
            if(str != ''){
              this.selectedTag2 = str.split(",")
            }else{
              this.selectedTag2 = []
            }
            
            this.filterVisible2 = false
            this.filterNum2 = 0
            if(this.maxNum2 != null){
              this.filterNum2 ++
            }
            if(this.minNum2 != null){
              this.filterNum2 ++
            }
            if(this.selectedTag2.length !=0){
              this.filterNum2 ++
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryCode() {
        const appId = 'wx0c399502b45aa502'; // 替换为你的 App ID
        const redirectUri = encodeURIComponent('https://www.anybridges.com/detail?id='+this.id+'&userid='+this.shareUser); // 替换为你的回调链接    https://www.anybridges.com/index
        const scope = 'snsapi_userinfo';
        const state = this.id; // 随机字符串
        const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&id=${state}#wechat_redirect`;
        window.location.href = authUrl; // 跳转到微信授权页面
      },
      loginWx() {
        if(this.shareUser==undefined||(this.shareUser=="undefined")){
          this.shareUser=''
        }
        this.axios.post('/api/login/wechatLogin', {
          code: this.code,
          shareUser: this.shareUser
        })
        .then(response=> {
          if(response.data.code == 0){
            this.userId = response.data.data.userId
            if(response.data.data.flag == 1){
              this.$toast('登录成功')
              this.sessionData('set','token',response.data.data.token);
              this.pcVisible = false
              // location.reload(); 
            }else{
              // this.$toast('未绑定手机号')
              this.screenVisible = true
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.shareUser = this.$route.query.userid
      let token = this.sessionData('get','token')
      if(token == null||token == undefined){
        this.isLogin = false
      }else{
        this.isLogin = true
      }
      this.code = this.$route.query.code
      if(this.code!=''&&this.code!=undefined&&localStorage.getItem('token') ==null){
        this.loginWx()
      }
      this.queryRobotDetail()
      this.getCurrentDate()
      this.queryIfHasData()
      this.queryWaitingCheckCount()
      this.queryTag()
      this.queryCallLog()
      this.queryWaitingCallCount()
      this.queryCity()
      this.queryCurrentLile()
      this.queryFilter()
      const randomNum = Math.floor(Math.random() * 5);
      let arr = [
        "别再为打电话而心慌意乱了，让我们的电话机器人来替你打电话，毕竟它比你更能忍受客户的冷漠！",
        "销售业绩不理想？别担心，电话机器人外呼系统来帮你，毕竟它的“社交能力”比你强多了！",
        "想要开发意向客户？交给我们的电话机器人，它打电话的热情，绝对能让你感到羞愧！",
        "当你在犹豫要不要拨打那通电话时，电话机器人已经在为你争取客户了，真是个不知疲倦的“工作狂”！",
        "销售人员的“秘密武器”，电话机器人外呼系统，帮你打电话、开发客户，连你的业绩都感到害羞了！"
      ]
      this.advertisement = arr[randomNum]
      this.startRefreshing()
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .index{
      width: 100%;
      height: 100%;
      background: #fff;
      position: relative;
      overflow-y: auto;
    }
    .banner{
      background: url('../assets/image/top-bg.jpg') no-repeat center center / cover;
      width: 100%;
      height: 212px;
      z-index: 10000;
      /* background-attachment: fixed; */
    }
    .head-banner1{
      height: 60px;
      position: fixed;
      width: 100%;
      left: 0;
      top:0;
      overflow: hidden;
      z-index: 1000;
    }
    .head-banner2{
      background: url('../assets/image/top-bg-1.png') no-repeat center center / cover;
      height: 60px;
      position: fixed;
      width: 100%;
      left: 0;
      top:0;
      overflow: hidden;
      z-index: 1000;
    }
    .common-cdr{
      display: inline-block;
      padding: 0 8px;
      color: rgba(103, 110, 125, 1);
      line-height: 22px;
      margin-top:3px;
      font-size: 12px;
    }
    .active-cdr{
      display: inline-block;
      padding: 0 8px;
      color: rgba(44, 54, 74, 1);
      font-weight: bold;
      background: #fff;
      height: 22px;
      line-height: 22px;
      border-radius: 6px;
      font-size: 12px;
      box-shadow: 0px 0px 5px 0px rgba(44, 54, 74, 0.16);
    }
    .line-active{
      background:rgba(120, 86, 255, 0.12);
    }
    .line-common{
      background:rgba(179, 184, 193, 0.16);
    }
    .custom-input:focus {
      box-shadow: 0 0 0 0 #fff !important; /* 自定义阴影 */
      outline: none !important; /* 去除默认的 outline */
    }
    .selected-member{
      color: rgba(120, 86, 255, 1);
    }
  </style>
  