<template>
  <div style="width: 100%;height: 100%;background:rgba(249, 249, 249, 1);">
    <div style="height: 84px;position: fixed;background: #fff;left:0;top:0;width: 100%;padding: 40px 16px 0;">
      <a-icon @click="goback" style="font-size:20px;color:rgba(4, 4, 4, 1);float: left;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;margin-left:6px;">{{currentPhone}}</span>
      <div @click="aiSearch" style="display: inline-block;position: relative;width: 68px;height:24px;margin-left:12px;line-height: 24px;color:rgba(120, 86, 255, 1);font-size:12px;background:rgba(120, 86, 255, 0.12);border-radius:4px;text-align: center;">
        <a-icon type="search" style="color:rgba(120, 86, 255, 1);margin-right:4px;" />
        <span style="font-weight:bold">AI搜索</span>
      </div>
      <img @click="goShareDel" style="width: 20px;float: right;z-index:10000000" src="../assets/image/share-b.png" alt="">
      <div style="float: right;position: relative;margin-right:15px;" @click="showFilter">
        <a-icon type="funnel-plot" style="font-size:22px;" />
        <span style="width: 14px;height: 14px;border-radius: 7px;display: inline-block;color: #fff;font-weight: bold;background: rgba(120, 86, 255, 1);text-align: center;line-height: 14px;position: absolute;right: -5px;top:-7px;font-size:10px;">{{ filterNum }}</span>
      </div>
    </div>
    <div style="height: 100%;padding:84px 0;">
      <div style="height: 100%;padding: 12px 0 0 16px;">
        <div style="color:rgba(179, 184, 193, 1);font-size:12px;text-align: center;">{{chatDelData.callTime}}</div>
        <div style="text-align:center;color:rgba(179, 184, 193, 1);font-size:12px;margin-top: 4px;">{{chatDelData.line}}</div>
        <div :class="chatDelData.labelList.length==0?'need-hide':''" style="height: 54px;padding-right: 16px;overflow-y: auto;margin-top:8px;margin-bottom: 4px;">
          <span v-for="(item,index) in chatDelData.labelList" :key="index" style="color:rgba(103, 110, 125, 1);background:rgba(243, 245, 248, 1);border:1px solid rgba(217, 217, 217, 1);border-radius: 4px;margin-bottom: 6px;margin-right: 6px;display: inline-block;height: 24px;line-height: 24px;padding: 0 12px;font-size:12px;">{{item}}</span>
        </div>
        <div style="margin-top:12px;" v-for="(item,index) in chatDelData.recordList" :key="index">
          <div v-if="item.role==1" :class="item.word==''?'need-hide':''" style="max-width: 70%;display: flex;margin-top:12px">
            <img style="width:36px;height:36px;border-radius: 18px;" :src="avatar" alt="">
            <div style="flex: 1;margin-left: 8px;">
              <span style="background:rgba(244, 244, 245, 1);border:1px solid rgba(229, 231, 235, 1);padding:8px;border-radius: 8px;display: inline-block;color:rgba(18, 18, 18, 1);font-size:14px;">{{item.word}}</span>
            </div>
          </div>
          <div style="clear: both"></div>
          <div v-if="item.role==2" :class="item.word==''?'need-hide':''" style="max-width: 70%;float: right;padding: 8px;background:rgba(120, 86, 255, 0.12);border: 1px solid rgba(229, 231, 235, 1);border-radius: 8px;color:rgba(18, 18, 18, 1);font-size: 14px;margin-top:12px;margin-right: 16px;">
            {{item.word}}
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top:24px;">
          <a-row style="text-align: center;">
            <a-col :span="8">
              <div v-if="chatDelData.errorCorrectionStatus==1" @click="correctMis" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane1.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">纠错学习</div>
              </div>
              <div v-if="chatDelData.errorCorrectionStatus==2" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane2.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">处理中</div>
              </div>
              <div v-if="chatDelData.errorCorrectionStatus==3" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane3.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">优化完成</div>
              </div>
              <div v-if="chatDelData.errorCorrectionStatus==4" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane4.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">优化失败</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div @click="collectChat(2)" v-if="chatDelData.collectStatus==1" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div>
                  <img style="width: 24px;font-weight: bold;margin-top: 8px;" src="../assets/image/star-kong.png" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">收藏</div>
              </div>
              <div @click="collectChat(1)" v-if="chatDelData.collectStatus==2" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div>
                  <img style="width: 24px;font-weight: bold;margin-top: 8px;" src="../assets/image/star1.png" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">取消</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div @click="nextData" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div>
                  <img style="width: 24px;font-weight: bold;margin-top: 8px;" src="../assets/image/pass.svg" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">剩{{waitingCheckCount}}条</div>
              </div>
            </a-col>
          </a-row>
          
         
        </div>
        <div style="height:96px"></div>
      </div>
      
    </div>
    <div style="position: fixed;bottom:24px;padding: 0 16px;left:0;width:100%;">
      <div style="width: 100%;border-radius: 8px;height: 60px;padding:12px;">
        <!-- <div ref="waveform_Ref" style="display: none;"></div>
        <div @click="playMusic" v-if="!playing" style="display: inline-block;width:36px;height:36px;text-align: center;line-height: 36px;border-radius: 18px;background:#fff;;">
          <a-icon style="color:rgba(44, 54, 74, 1);font-size:24px;margin-top:6px;" type="caret-right" />
        </div>
        <div v-if="playing" @click="playMusic" style="display: inline-block;width:36px;height:36px;text-align: center;line-height: 36px;border-radius: 18px;background:#fff;;">
          <a-icon style="color:rgba(44, 54, 74, 1);font-size:24px;margin-top:6px;" type="pause" />
        </div>
        <div style="flex: 1;margin-left:8px;">
          <div style="color:rgba(255, 255, 255, 1);font-weight: bold;font-size:16px;">{{chatDelData.phone}}</div>
          <div style="color:rgba(179, 184, 193, 1);font-size:9px;margin-top:1px;">
            <span>{{currentTime}}</span>
            <span style="display: inline-block;width:150px;height: 6px;border-radius: 3px;background:rgba(255, 255, 255, 0.3);margin:0 8px;"></span>
            <span>{{ urlDuration }}</span>
          </div>
        </div> -->
        <audio controls style="width:100%" :src="voiceUrl">
      
        </audio>


      </div>
    </div>

    <div v-if="shareVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>转发链接</span>
            <a-icon @click="closeShare" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <a-input v-model="copyHtml2" id="copyHtml2" readonly="readonly" style="width:100%;height:40px;border-radius:8px;margin:0 0 20px 0;background:rgba(179, 184, 193, 0.16);"/>
          <div @click="copySite" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;">复制url地址</div>
        </div>
      </div>
    </div>
    <div v-if="ifshowStatus" style="height: 40px;line-height: 40px;text-align: center;padding: 0 16px;position: fixed;width:100%;left:0;bottom:95px;">
      <div style="border-radius: 8px;background:rgba(44, 54, 74, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);height: 40px;color:#fff;font-size:13px">{{ statusText }}</div>
    </div>
    <div v-if="filterVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>设定未读条件</span>
            <a-icon @click="closeFilter" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px">标签多选</div>
          <div>
            <a-select
              mode="multiple"
              size="default"
              placeholder="请选择"
              style="width: 100%;margin: 12px 0 20px 0;"
              v-model="selectedTag"
            >
              <a-select-option v-for="(item,index) in tagList" :key='index' :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px">通话时长范围</div>
          <div style="color:rgba(44, 54, 74, 1);font-size:15px;margin-top:12px">
            <span>大于</span>
            <a-input type="number" v-model="minNum" placeholder="请输入数值" style="width: 160px;border:none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;height: 40px;margin:0 12px;"/>
            <span>秒；</span>
          </div>
          <div style="color:rgba(44, 54, 74, 1);font-size:15px;margin-top:12px">
            <span>小于</span>
            <a-input type="number" v-model="maxNum" placeholder="请输入数值" style="width: 160px;border:none;background:rgba(179, 184, 193, 0.16);border-radius: 8px;height: 40px;margin:0 12px;"/>
            <span>秒；</span>
          </div>
          <div @click="sureFilter" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:24px;">保存设定</div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="ifShowSearch" title="AI搜索">
      <div style="text-align: center;margin:80px 0 35px">
        <a-icon style="font-size:25px;" type="search" />
      </div>
      <div style="color:rgba(164, 164, 164, 1);font-size:16px;text-align: center;margin-bottom: 82px;">正在搜索，请耐心等待...</div>
    </van-action-sheet>
    <van-action-sheet v-model="ifShowResult" title="搜索结果">
      <div style="max-height:300px;overflow-y: auto;">
        <div v-for="(item,index) in searchResult" :key="index">
          <div style="margin-top:20px;padding:0 20px;">
            <div style="height:36px;line-height:36px;text-align:center;background:rgba(243, 245, 248, 1);border-radius:8px;color:rgba(44, 54, 74, 1);font-size:12px;">{{item.companyName}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">企业信息</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.companyName}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">法人代表</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.juridicalPerson}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">注册资本</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.registeredCapital}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">成立日期</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.establishTime}}</div>
          </div>
        </div>
      </div>
      <div style="padding:0 20px 24px;margin-top:24px;">
        <div @click="getResult" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;">知道了</div>
      </div>
    </van-action-sheet>
  </div>
</template>
  
  <script>
  import WaveSurfer from "wavesurfer.js";
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    data() {
        return {
          chatList: [
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
          ],
          urlDuration: 0,
          currentTime: 0,
          wavesurfer: null,
          voiceUrl: '/1.mp3',
          playing: false,
          id: {
            durationQ: '',
            durationZ: '',
            labels: '',
          },
          obj: '',
          chatDelData: '',
          waitingCheckCount: 0,
          copyHtml2: '',
          shareVisible: false,
          statusText: '',
          ifshowStatus: false,
          avatar: '',
          currentPhone: '',
          filterVisible: false,
          maxNum: null,
          minNum: null,
          tagList: [],
          selectedTag: [],
          filterNum: 0,
          ifShowSearch: false,
          searchResult: [],
          ifShowResult: false,
          ifShowTip3: false,
        }
    },
    methods: {
      playMusic() {
          this.wavesurfer.playPause.bind(this.wavesurfer)();
          this.playing = !this.playing;
      },
      beforeDestroy() {
        // 销毁 WaveSurfer 实例
        if (this.wavesurfer) {
          this.wavesurfer.destroy();
        }
      },
      goback() {
        this.$router.go(-1)
      },
      queryChatDel() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/waitingCheckCallRecord', 
            params: {
              id: this.id,
              callDate: this.obj.callDate,
              // durationQ: this.obj.durationQ,
              // durationZ: this.obj.durationZ,
              // labels: this.obj.labels,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            const randomNumber = Math.floor(Math.random() * 900000) + 100000;
            console.log(response.data.data)
            this.chatDelData = response.data.data
            this.voiceUrl = response.data.data.voiceUrl+'?randomnum='+randomNumber
            this.waitingCheckCount = response.data.data.leftCount
            this.currentPhone = response.data.data.phone
            this.$forceUpdate()
            // this.queryWaitingCheckCount()
            this.drewLine()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      drewLine() {
        this.$nextTick(() => {
          this.wavesurfer = WaveSurfer.create({
            // 波形图的容器
            container: this.$refs.waveform_Ref,
            // 已播放波形的颜色
            progressColor: "#D2D2D2",
            // 未播放波形的颜色
            waveColor: "#86FCC7",
            // 波形图的高度，单位为px
            height: 68,
          });
          this.wavesurfer.load(this.chatDelData.voiceUrl);
          // this.wavesurfer.load(this.voiceUrl);
          let that = this
          this.wavesurfer.on('ready', function () {
            // 当音频加载完成后，获取时长
            that.urlDuration = that.wavesurfer.getDuration().toFixed(2);
          });
          this.wavesurfer.on('audioprocess', function (time) {
            // 'time' 参数是当前播放位置的秒数
            that.currentTime = time.toFixed(2)
            if(that.urlDuration == that.currentTime){
                that.playing = false
            }
          });
          this.$forceUpdate();
        });
      },
      collectChat(collectStatus) {
        this.axios.post('/api/callData/updateCollectStatus', {
          recordId: this.chatDelData.id,
          collectStatus: collectStatus
        })
        .then(response=> {
          if(response.data.code == 0){
            this.chatDelData.collectStatus = collectStatus
            if(collectStatus == 1){
              this.$toast('取消成功')
            }else{
              this.$toast('收藏成功')
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      queryWaitingCheckCount() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/waitingCheckCount', 
            params: {
              id: this.id,
              callDate: this.obj.callDate,
              // durationQ: this.obj.durationQ,
              // durationZ: this.obj.durationZ,
              // labels: this.obj.labels,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.waitingCheckCount = response.data.data.count
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      nextData() {
        if(this.waitingCheckCount!=0){
          this.urlDuration = 0
          this.currentTime = 0
          this.voiceUrl = ''
          console.log(this.wavesurfer)
          if (this.wavesurfer) {
            this.wavesurfer.destroy();
          }
          this.queryChatDel()
          
        }else{
          this.$toast('暂无最新数据')
        }
      },
      goShareDel() {
        // this.$router.push("/callShare")
        this.shareVisible = true
        setTimeout(()=>{
          this.queryRobotToken()
        },500)
      },
      queryRobotToken() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/shareCallRecordToken', 
            params: {
              recordId: this.chatDelData.id
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            let token = response.data.data.shareCallRecordToken
            let url = ''
            let protocol = window.location.protocol;
            let host = window.location.host;
            url = protocol + '//' + host + '/#/callShare?token='+token
            console.log(url)
            let text = url
            let input = document.getElementById("copyHtml2");
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.copyHtml2 = url
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      closeShare() {
        this.shareVisible = false
      },
      copySite() {
        this.shareVisible = false
        this.statusText = '链接已复制，快去分享吧'
        this.ifshowStatus = true
        setTimeout(()=>{
          this.ifshowStatus = false
        },1500)
      },
      hideModel() {
        this.shareVisible = false
        this.filterVisible = false
      },
      correctMis() {
        this.axios.post('/api/callData/errorCorrection', {
          recordId: this.chatDelData.id,
        })
        .then(response=> {
          if(response.data.code == 0){
            this.statusText = '错误话单已发送，请等待处理结果'
            this.ifshowStatus = true
            setTimeout(()=>{
              this.ifshowStatus = false
            },1500)
            // this.queryChatDel()
            this.chatDelData.errorCorrectionStatus = 2
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
        
      },
      showFilter() {
        this.filterVisible = true
      },
      closeFilter() {
        this.filterVisible = false
      },
      sureFilter() {
        this.filterVisible = false
        this.filterNum = 0
        if(this.maxNum != null&&this.maxNum != ''){
          this.filterNum ++
        }
        if(this.minNum != null&&this.minNum != ''){
          this.filterNum ++
        }
        if(this.selectedTag.length !=0){
          this.filterNum ++
        }
        this.updateFilter()
      },
      updateFilter() {
        let str = ''
        if(this.selectedTag.length != 0){
          for(let i = 0; i < this.selectedTag.length; i++){
            str+=this.selectedTag[i]+','
          }
          str = str.slice(0, -1)
        }
        this.axios.post('/api/project/updateCondition', {
          id: this.id,
          durationQ: this.minNum==''?null:this.minNum,
          durationZ: this.maxNum==''?null:this.maxNum,
          labels: str,
        })
        .then(response=> {
          if(response.data.code == 0){
            this.obj.durationQ = this.minNum
            this.obj.durationZ = this.maxNum
            this.obj.labels = str
            this.queryWaitingCheckCount()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      queryFilter() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/queryCondition', 
            params: {
              id: this.id,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            // console.log(response.data.data)
            this.maxNum = response.data.data.durationZ
            this.minNum = response.data.data.durationQ
            let str = response.data.data.labels
            if(str != ''){
              this.selectedTag = str.split(",")
            }else{
              this.selectedTag = []
            }
            
            this.filterVisible = false
            this.filterNum = 0
            if(this.maxNum != null){
              this.filterNum ++
            }
            if(this.minNum != null){
              this.filterNum ++
            }
            if(this.selectedTag.length !=0){
              this.filterNum ++
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryTag() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/labelList', 
            params: {
              id: this.id,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.tagList = response.data.data
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      getResult() {
        this.ifShowResult = false
      },
      aiSearch() {
        this.ifShowSearch = true
        this.axios( 
          {
            method:'get',
            url:'/api/contacts/aiSearch', 
            params:{
              phone: this.currentPhone
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.ifShowSearch = false
            this.searchResult = response.data.data
            if(this.searchResult.length > 0){
              this.ifShowResult = true
            }else{
              this.ifShowTip3 = true
              setTimeout(()=>{
                this.ifShowTip3 = false
              },1500)
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
    },
    mounted() {
      this.id = this.$route.query.id
      this.queryChatDel()
      this.avatar = this.$route.query.avatar
      this.queryFilter()
      this.queryTag()
      // this.queryWaitingCheckCount()
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .need-hide{
      display: none !important;
    }
    .my-input::placeholder {
      color: rgba(120, 86, 255, 1); 
      font-size: 12px;
    }
  </style>
  